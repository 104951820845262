const StaticModule = () => import(/* webpackChunkName: "static-module" */ './views/Module.vue'); 
const Markets = () => import(/* webpackChunkName: "markets" */ './views/Markets.vue');
const LiveCasinoInfo = () => import(/* webpackChunkName: "markets" */ './views/LiveCasinoInfo.vue');
const LiveCardInfo = () => import(/* webpackChunkName: "markets" */ './views/LiveCardInfo.vue');
const LiveCardDetails = () => import(/* webpackChunkName: "markets" */ './views/LiveCardDetails.vue');
const Static = () => import(/* webpackChunkName: "static" */ './views/Static.vue');
const Faqs = () => import(/* webpackChunkName: "faqs" */ './views/Faqs.vue');
const Affiliate = () => import(/* webpackChunkName: "affiliate" */ './views/Affiliate.vue');
const RulesRegulations = () => import(/* webpackChunkName: "rules-regulations" */ './views/RulesRegulations.vue');
const FantasyPremium = () => import(/* webpackChunkName: "fantasy-premium" */ './views/FantasyPremium.vue');

const StaticRoutes =   {
    path: '/',
    component: StaticModule,
    children: [
      {
        path: 'markets',
        name: 'markets',
        component: Markets
      },
      {
        path: 'markets/live-casino',
        name: 'livecasino-info',
        component: LiveCasinoInfo
      },
      {
        path: 'markets/live-card',
        name: 'livecard-info',
        component: LiveCardInfo
      },
      {
        path: 'markets/:type',
        name: 'livecard-Details',
        component: LiveCardDetails
      },
      {
        path: 'static/:type',
        name: 'static',
        component: Static
      },
      {
        path: 'faqs',
        name: 'faqs',
        component: Faqs
      },
      {
        path: 'affiliate',
        name: 'affiliate',
        component: Affiliate
      },
      {
        path: 'rules-regulations',
        name: 'rules-regulations',
        component: RulesRegulations
      },
      {
        path: 'fantasy-premium/:type',
        name: 'fantasy-premium',
        component: FantasyPremium
      },
    ],
  }

export default StaticRoutes;