const state = {
    user: null,
    chips: null,
    token: null,
    eventManage:null,
    eventDetailHideSections: []
};
const getters = {
    isAuthenticated: state => !!state?.user,
    stateUser: state => state?.user,
    chips: state => state?.chips,
    token: state => state?.token,
    eventManage: state => state?.eventManage,
    eventDetailHideSections: state => state?.eventDetailHideSections,
};
const actions = {
    setUser({ commit }, user) {
        commit('setUser', user);
    },

    setChips({ commit }, chips) {
        commit('setChips', chips);
    },

    setToken({ commit }, token) {
        commit('setToken', token);
    },


    setEventManage({ commit }, token) {
        commit('setEventManage', token);
    },

    seteventDetailHideSections({ commit }, filter) {
        console.log(filter)
        commit('seteventDetailHideSections', filter);
    },

};
const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setChips(state, chips) {
        state.chips = chips
    },

    setToken(state, token) {
        state.token = token;
    },

    setEventManage(state, eventManage) {
        state.eventManage = eventManage;
    },

    seteventDetailHideSections(state, eventDetailHideSections) {
        state.eventDetailHideSections = eventDetailHideSections;
    },

};
export default {
    state,
    getters,
    actions,
    mutations
};