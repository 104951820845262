<template>
    <div class="modal fade worli-Bets-Modal Betamount" id="clickBetValue" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <div class="thm-heading">
                            <div class="match-faq statement-match-faq">
                                <div class="match-head">
                                    <h5>{{ translatedLangData('click-bet-value','Click Bet Value')}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn-close"  @click="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="stakeed-sec withdraw-sec bg-transparent p-0 border-0 singlestakeed-sec">
                        <div class="thm-gradient-but">
                            <ul v-if="inputvalue">
                                <li>
                                    <input  @click="selectInputValue(0)" :class="{ 'border-input': inputvalue[0].isSelected }"
                                        :value="inputvalue[0].value" class="btn-thm m-auto" maxlength="4" @input="isNumber($event, 0)">
                                </li>
                                <li>
                                    <input  @click="selectInputValue(1)" :class="{ 'border-input': inputvalue[1].isSelected }"
                                        :value="inputvalue[1].value" class="btn-thm m-auto" maxlength="4" @input="isNumber($event, 1)">
                                </li>
                                <li>
                                    <input @click="selectInputValue(2)" :class="{ 'border-input': inputvalue[2].isSelected }"
                                        :value="inputvalue[2].value" class="btn-thm m-auto" maxlength="4"  @input="isNumber($event, 2)">
                                </li>
                                <li>
                                    <input @click="selectInputValue(3)" :class="{ 'border-input': inputvalue[3].isSelected }"
                                        :value="inputvalue[3].value" class="btn-thm m-auto" maxlength="4"  @input="isNumber($event, 3)">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="header-but  info-footer text-center mt-3">
                        <button ref="close_btn" type="button" @click="closeModal" class="thm-but thm-bor-but me-2 click-bet-value-btn"
                            data-bs-dismiss="modal">{{ translatedLangData('cancel','Cancel')}}</button>
                            <a @click="saveClickBetValue()"  class="thm-but">{{ translatedLangData('save','Save')}}</a>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ClickBetModal",
    inject:['translatedLangData'],
    data() {
        return {
            inputvalue: null,
            selectedValue:false
        }
    },
    mounted() {
        if (this.getClickBetValue()) {
            this.inputvalue = this.getClickBetValue();
        }
        else {
            this.inputvalue = [
                {
                    value: 1000,
                    isSelected: true,
                },
                {
                    value: 2000,
                    isSelected: false,
                },
                {
                    value: 3000,
                    isSelected: false,
                },
                {
                    value: 4000,
                    isSelected: false,
                }
            ]
        }
    },
    methods: {
        getClickBetValue() {
            return this.$store.getters.clickBetValue?[...this.$store.getters.clickBetValue]:null;
        },
        isNumber(evt, index) {
            let newValue = evt.target.value;
            if (newValue.length === 1 && newValue === '0') {
                this.inputvalue[index].value = '';
                evt.target.value = '';
            } else if (evt.data == '0' && newValue.startsWith('0')) {
                evt.target.value = Number(this.inputvalue[index].value);
            } else if (/^\d+$/.test(newValue)) {
                this.inputvalue[index].value = Number(newValue);
            } else {
                if (newValue !== '') {
                    evt.target.value = Number(this.inputvalue[index].value);
                } else {
                    this.inputvalue[index].value = '';
                }
            }
        },

        selectInputValue(index) { 
            this.selectedValue =  true;    
            let newinputvalue = JSON.parse(JSON.stringify(this.inputvalue)) 
            this.inputvalue =  newinputvalue
            for (let i = 0; i < this.inputvalue.length; i++) {
                this.inputvalue[i].isSelected = false;
            }
            this.inputvalue[index].isSelected = true;
        },
        saveClickBetValue() {
                if(this.inputvalue.some(obj => obj.isSelected)) {
                    const valueSet = new Set();
                    for (let i = 0; i < this.inputvalue.length; i++) {
                        const value = this.inputvalue[i].value;
                        if (valueSet.has(value)) {
                            this.$emit('error-modal',this.translatedLangData('stake-duplicate-msg','Duplicate Values Are Not Allowed.'));
                            this.inputvalue = this.getClickBetValue();
                            return;
                        } else {
                            valueSet.add(value);
                        }
                        if (value < 100 || value > 5000) {
                            
                            this.$emit('error-modal', this.translatedLangData('value-btw-100-5000','Value should be in between 100 to 5000'));
                            this.inputvalue = this.getClickBetValue();
                            return;
                        }
                    }
                    this.$store.dispatch('setClickBetValue', this.inputvalue);
                    this.$store.dispatch('setActiveClickBet', true)
                    this.$emit('success-modal', 'Click bet value updated successfully.');
                    this.selectedValue =  false;  
                    this.$refs.close_btn.click();
                }
            },
            closeModal() {
                this.selectedValue = false;
                const clickBetValue = this.getClickBetValue();
                if (clickBetValue) {
                    this.inputvalue = JSON.parse(JSON.stringify([...clickBetValue]));
                } else {
                    this.inputvalue = [
                        {
                            value: 1000,
                        },
                        {
                            value: 2000,
                        },
                        {
                            value: 3000,
                        },
                        {
                            value: 4000,
                        }
                    ];
                }
                this.$emit('close-modal', this.$store.getters.clickBetActive.isSelected ? this.$store.getters.clickBetActive.isSelected : this.$store.getters.clickBetActive);
            }
    },

    emits: ['close', 'success-modal', 'error-modal'],
};
</script>
<style scoped>
.border-input {
    border: 2px solid  !important;
}
</style>