const NotificationsModule = () => import(/* webpackChunkName: "notifications-module" */ './views/Module.vue'); 
const Notifications = () => import(/* webpackChunkName: "notifications" */ './views/Notifications.vue');


const NotificationsRoutes =   {
    path: '/',
    component: NotificationsModule,
    children: [
      {
        path: 'notifications',
        name: 'notifications',
        component: Notifications
      }
    ],
  }

export default NotificationsRoutes;