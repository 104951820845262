import moment from 'moment';

export function convertUTCDateToLocalDate(timestamp) {
    // var utc = new Date();
    // var offset = utc.getTimezoneOffset();
    // var local = new Date(timestamp + offset * 60000);
    // return local;
   return moment.unix(timestamp).format('DD MMM YYYY HH:mm:ss');
}

export function getUtcTimeStampFromDate(date){
    var utcDate = date.toUTCString();
    return (new Date(utcDate).getTime())/1000;
}

export function getSubtractedDaysDate(date,days)
{  
    var day=date.getTime() - (days*24*60*60*1000);
    return date.setTime(day);
}




