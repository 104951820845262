const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue'); 
const LiveCasino = () => import(/* webpackChunkName: "live-casino" */ './views/LiveCasino.vue');
const LiveCard = () => import(/* webpackChunkName: "live-card" */ './views/LiveCard.vue');
const SlotGames = () => import(/* webpackChunkName: "slot-games" */ './views/SlotGames.vue');
const CrashGames = () => import(/* webpackChunkName: "slot-games" */ './views/CrashGames.vue');
const ResponsibleGambling = () => import(/* webpackChunkName: "slot-games" */ './components/ResponsibleGambling.vue');
const OnlineCricketBetting = () => import(/* webpackChunkName: "slot-games" */ './components/OnlineCricBetting.vue');
const OnlineBetting = () => import('./components/OnlineBetting.vue');
const IplBetting = () => import('./components/IplBetting.vue');
const SoccerBetting = () => import('./components/SoccerBetting.vue');
const OnlineSportsBetting = () => import('./components/OnlineSportBetting.vue');
const TennisBetting = () => import('./components/TennisBetting.vue');
const MongoBettingApp = () => import('./components/MangoBettingApp.vue');


const GamesRoutes =   {
    path: '/',
    component: GamesModule,
    children: [
      {
        path: 'live-card',
        name: 'live-card',
        component: LiveCard
      },
      {
        path: 'live-casino',
        name: 'live-casino',
        component: LiveCasino
      },
      {
        path: 'slot-games',
        name: 'slot-games',
        component: SlotGames
      },
      {
        path: 'crash-games',
        name: 'crash-games',
        component: CrashGames
      },

      {
        path: 'responsible-gambling',
        name: 'responsible-gambling',
        component: ResponsibleGambling
      },

      {
        path: 'online-cricket-betting',
        name: 'online-cricket-betting',
        component: OnlineCricketBetting
      },

      {
        path: 'online-betting',
        name: 'online-betting',
        component: OnlineBetting
      },

      {
        path: 'ipl-betting',
        name: 'ipl-betting',
        component: IplBetting
      },

      {
        path: 'soccer-betting',
        name: 'soccer-betting',
        component: SoccerBetting
      },

      {
        path: 'online-sports-betting',
        name: 'online-sports-betting',
        component: OnlineSportsBetting
      },

      {
        path: 'tennis-betting',
        name: 'tennis-betting',
        component: TennisBetting
      },

      {
        path: 'MONGO-online-app',
        name: 'MONGO-online-app',
        component: MongoBettingApp
      },

    ],
  }

export default GamesRoutes;