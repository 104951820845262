const BonusModule = () => import(/* webpackChunkName: "bonus-module" */ './views/Module.vue'); 
const AwaitingBonus = () => import(/* webpackChunkName: "awaiting-bonus" */ './views/AwaitingBonus.vue');
const PendingBonus = () => import(/* webpackChunkName: "pending-bonus" */ './views/PendingBonus.vue');

const BonusRoutes =   {
    path: '/',
    component: BonusModule,
    children: [
      {
        path: 'awaiting-bonus',
        name: 'awaiting-bonus',
        component: AwaitingBonus
      },
      {
        path: 'pending-bonus',
        name: 'pending-bonus',
        component: PendingBonus
      }
    ],
  }

export default BonusRoutes;