let gameItem=null;
export function setGameItem(item)
{
    gameItem = item;
}

export function getGameItem(){
    return gameItem;
}


let gameID=null;
export function setGameID(id)
{
    gameID = id;
}

export function getGameID(){
    return gameID;
}