const FavouritesModule = () => import(/* webpackChunkName: "favourites-module" */ './views/Module.vue'); 
const Favourites = () => import(/* webpackChunkName: "favourites" */ './views/Favourites.vue');


const FavouritesRoutes =   {
    path: '/',
    component: FavouritesModule,
    children: [
      {
        path: 'favourites',
        name: 'favourites',
        component: Favourites
      }
    ],
  }

export default FavouritesRoutes;