<template>
    <div class="modal fade " id="informationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg   modal-dialog modal-dialog-centered information-modal-wrapper">
            <div class="modal-content main-content">
                <div class="modal-header">
                    <h4 class="modal-title"> {{ translatedLangData('bonus-rules','Bonus Rules')}}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="back-modal p-4">
                        <div class="mene-details information-modal-sec">
                            <template v-for="(rule, r_index, rule_num) in siteRuleData" :key="r_index">
                                <template v-if="r_index != 'General'">
                                    <strong>{{ rule_num }}. {{ r_index }}</strong>
                                    <div class="menu-details-list all-rule" v-html="rule"></div>
                                </template>
                            </template>
                        </div>
                        <div class="back-modal" v-if="!siteRuleData || !Object.keys(siteRuleData).length">
                            <div class="mene-details information-modal-sec">
                                <div class="menu-details-list">
                                    {{ translatedLangData('no-rules-available','No rules available.')}}
                                </div>
                            </div>
                        </div>
                        <!-- <div class="conditions-sec">
                        <a class="conditions-btn thm-but">Terms and Conditions</a>
                    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BonusInfoModal',
    props:['siteRuleData'],
    inject:['translatedLangData'],

}
</script>
<style>
.main-content {
    background-color: #ffffff !important;
}
.menu-details-list.all-rule p {
    padding: 15px;
}
</style>