<template>
    <div class="body-onload-modal">
        <div class="modal fade" id="DownloadAppModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="download-app-modal-body">
                        <div class="modal-body">
                            <button type="button" class="close-onload-modal" data-bs-dismiss="modal" aria-label="Close">
                                <img loading="lazy" src="@/assets/images/close-btn.webp" alt="close btn">
                            </button>
                            <div class="web-icon-h">
                                <img :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings?.logo"
                                    alt="logo">
                            </div>
                            <div class="app-btn-and-h-sec">
                                <div class="app-h-main">
                                    <h2>download the app</h2>
                                    <p>For a smoother, better experience</p>
                                </div>
                                <div class="download-app-custom-btn">
                                    <a type="button" href="#install-apk-model" data-bs-toggle="modal">
                                        Download <span><img loading="lazy" src="@/assets/images/android-down.webp" alt="android down"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <DownloadApkModal />
</template>

<script>
import DownloadApkModal from '@/shared/components/modal/DownloadApkModal.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'DownloadApp',
    components: {
        DownloadApkModal
    },
    computed: {
        ...mapGetters({
            siteSettings: 'siteSettings', stateUser: 'stateUser'
        })
    },

    methods: {
        goToDeposit() {
            this.$router.push('/wallet-deposit')
        },

    },
}
</script>