<template>
    <div class="install-apk-model">
        <div class="modal fade" id="install-apk-model" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true" data-bs-backdrop="static">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="thm-heading">
                            <h3 class="text-white">{{ translatedLangData('how-to-install-apk', 'How to Install APK') }}
                            </h3>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                src="@/assets/images/apk-modal-icon.webp" alt="apk-modal-icon"></button>
                    </div>
                    <div class="modal-body">
                        <div class="install-app-content">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="android-tab" data-bs-toggle="tab"
                                        data-bs-target="#android-panel" type="button" role="tab"
                                        aria-controls="android-panel" aria-selected="true">
                                        {{ translatedLangData('android', 'Android') }}
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="ios-tab" data-bs-toggle="tab"
                                        data-bs-target="#ios-panel" type="button" role="tab" aria-controls="ios-panel"
                                        aria-selected="false">
                                        {{ translatedLangData('ios', 'iOS') }}
                                    </button>
                                </li>
                            </ul>

                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="android-panel" role="tabpanel"
                                    aria-labelledby="android-tab">
                                    <div class="how-install-content">
                                        <img loading="lazy" src="@/assets/images/android.jpg" alt="android">
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="ios-panel" role="tabpanel" aria-labelledby="ios-tab">
                                    <div class="how-install-content">
                                        <img loading="lazy" src="@/assets/images/ios_img.jpg" alt="ios image">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn close-btn" data-bs-dismiss="modal">{{
                            translatedLangData('close','Close')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DownloadApkModal',
    inject: ['translatedLangData'],

}
</script>

<style></style>