<template>
    <!-- show more tab modal pop-up -->
    <div class="more-tab-show-m-view">

<div class="modal fade" id="more-item-tabs" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="show-more-tabs-modal-body">
            <ul>
                <li  v-for="(sport, pn_index) in primary_non_custom_sports" :key="pn_index" data-bs-dismiss="modal">
                    <router-link :to="'/sports/' + sport.slug + '/' + sport.id" >
                        <div>
                            <img :src="getSportImage(sport.id)" :alt="sport.name">
                        </div>
                        <div>
                            <span>{{ sport.id == 1 ? translatedLangData('soccer','Soccer' ) :translatedLangData(sport.name,sport.name ) }}</span>
                        </div>
                     </router-link>
                </li>
                <li>
                    <router-link to="/matka" >
                        <div data-bs-dismiss="modal">
                            <div>
                                <img loading="lazy" src="@/assets/images/matka-Layer_1.svg" alt="matka Layer">
                            </div>
                            <div>
                                <span>
                                    {{ translatedLangData('matka','Matka') }}
                                </span>
                            </div>
                        </div>
                    </router-link>
                </li>
                <li  v-for="(sport, sn_index) in secondary_non_custom_sports" :key="sn_index" data-bs-dismiss="modal">
                    <router-link :to="'/sports/' + sport.slug + '/' + sport.id" >
                        <div>
                            <img :src="getSportImage(sport.id)" :alt="sport.name">
                        </div>
                        <div>
                            <span>{{ sport.id == 1 ? translatedLangData('soccer','Soccer' ) :translatedLangData(sport.name,sport.name ) }}</span>
                        </div>
                     </router-link>
                </li>
                <li v-for="(c_sport, c_index) in custom_sports" :key="c_index" data-bs-dismiss="modal">
                    <router-link :to="'/sports/' + c_sport.slug.trim() + '/' + c_sport.id">
                        <div>
                            <img :src="getSportImage(c_sport.id)" :alt="c_sport.name">
                        </div>
                        <div>
                            <span>{{ c_sport.slug }}</span>
                        </div>
                    </router-link>
                </li>               
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: "MobileSportSelection",
    inject:['translatedLangData'],
    props:['non_custom_sports', 'custom_sports'],
    data(){
        return{
            sportsBookGames:[
            {
                "id": 'sr:sport:21',
                "name": 'Criket',
            },
            ]
        }
    },
    computed:{
        primary_non_custom_sports(){
            return this.non_custom_sports?.filter(sport => [1,2,3,4].includes(sport.rank));
        },
        secondary_non_custom_sports(){
            return this.non_custom_sports?.filter(sport => ![1,2,3,4].includes(sport.rank));
        },
    },
    methods: {
        gotoSportsBook(id){
            this.$router.push({ name: 'sports-book-wise', params: { id: id } })
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        
        getSportImage(sportId) {
            const imageMap = {
                4: require("@/assets/images/sports-cricket.png"),
                1: require("@/assets/images/sports-soccer.png"),
                2: require("@/assets/images/sports-tennis.png"),
                1477: require("@/assets/images/sports-rugby-league.png"),
                6: require("@/assets/images/sports-boxing.png"),
                5: require("@/assets/images/sports-rugby-union.png"),
                3: require("@/assets/images/sports-golf.png"),
                27454571: require("@/assets/images/sports-esports.png"),
                2152880: require("@/assets/images/sports-gaelic-football.png"),
                998917: require("@/assets/images/sports-volleyball.png"),
                7524: require("@/assets/images/sports-ice-hockey.png"),
                7522: require("@/assets/images/sports-basketball.png"),
                7511: require("@/assets/images/sports-baseball.png"),
                6423: require("@/assets/images/sports-american-football.png"),
                6422: require("@/assets/images/sports-snooker.png"),
                11: require("@/assets/images/sports-cycling.png"),
                8: require("@/assets/images/sports-motor-sport.png"),
                2378961: require("@/assets/images/sports-politics.png"),
                468328: require("@/assets/images/sports-handball.png"),
                61420: require("@/assets/images/sports-australian-rules.png"),
                3503: require("@/assets/images/sports-darts.png"),
                10: require("@/assets/images/sports-special-bets.png"),
                26420387: require("@/assets/images/sports-mixed-martial-arts.png"),
                4339: require("@/assets/images/sports-greyhound-racing.png"),
                7: require("@/assets/images/sports-horse-racing.png"),
            };
            return imageMap[sportId] || require("@/assets/images/sports-no.png");
        },
    }
}
</script>