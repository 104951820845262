import { createApp} from 'vue';
import App from './App.vue';
import router from "./router";
import store from "./store";
import VueCookies from 'vue3-cookies';
// import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/chat.css";
import "./assets/css/chatresponsive.css";
import "./assets/css/responsive.css";
import "./assets/css/style1.css";
import "./assets/fontawesome/css/all.css";
import "./assets/css/bootstrap-icons.css"
import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import './registerServiceWorker';

window.addEventListener('popstate', (event) => {
    if(document.querySelector('.modal.show')) {
        window.location.reload();
    }
})
router.beforeEach((to, from, next) => {
    const underMaintenance = store.getters.underMaintenance;
    const isMaintenancePage = to.name === 'Maintenance';
    const isAuthenticated = window.store?.getters.isAuthenticated;
    const lastLoginNotSet = !window.store?.getters?.stateUser?.last_login_at;
    const isResetPasswordPage = to.name === 'reset-password';
    if (underMaintenance) {
        if (!isMaintenancePage) {
            return next({ name: 'Maintenance' });
        }
    } else {
        if (isMaintenancePage) {
            return next({ name: 'Home' });
        }
    }
    if (isAuthenticated && lastLoginNotSet && !isResetPasswordPage) {
        return window.router.push({ name: 'reset-password' });
    }
    if (document.querySelector('.modal.show')) {
        return next(false);
    }
    next();
});

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueCookies);
app.mount("#app");

app.config.globalProperties.fantasy_server = "fantasycricket";
app.config.globalProperties.click_server = "fantasy";
