<template>
    <section class="navigation-and-tabs mt-2" loading="lazy">
        <div class="remove-padding">
            <div class="all-game-list">
                <ul>
                    <li class="top-game-bx">
                        <router-link to="/" aria-label="Inplay">
                            <img src="@/assets/images/inplay.a7c4dae.webp" alt="inplay">
                            <span>{{ translatedLangData('inplay','inplay')}}</span>
                        </router-link>
                    </li>
                    <li class="top-game-bx none-768" v-for="(sport, n_index) in non_custom_sports" :key="n_index"
                        v-show="checkInEventMange(sport.slug)">
                        <router-link :to="'/sports/' + sport.slug + '/' + sport.id" aria-label="game">
                            <img v-if="sport.id == 4" src="@/assets/images/cricket.5c05f66.webp" alt="cricket">
                            <img v-if="sport.id == 1" src="@/assets/images/soccer.9f718cc.webp" alt="soccer">
                            <img v-if="sport.id == 2" src="@/assets/images/tennis.fc30791.webp" alt="tennis">
                            <span>{{ translatedLangData(sport.slug,sport.name)}}</span>
                        </router-link>
                    </li>

                    <li class="top-game-bx ">
                        <a href="javascript:void(0);" class="blink" aria-label="Fantasy Pro">
                            <img class="pro-fant" src="@/assets/images/fantasybookicon.225b8cb.webp" alt="fantasy book">
                            <span class="pro-fant">{{ translatedLangData('fantasy-pro','Fantasy Pro')}}</span>
                        </a>
                    </li>
                    <li class="top-game-bx">
                        <router-link :to="{ name: 'racing-category', params: { type: 'HORSE_RACE' } }"
                            @click="type = 'HORSE_RACING'" :class="{ 'active': type == 'HORSE_RACING' }" aria-label="Horse Racing">
                            <img loading="lazy" src="@/assets/images/horse.webp" alt="horse">
                            <span>Horse Racing</span>
                        </router-link>
                    </li>
                    <li class="top-game-bx">
                        <router-link :to="{ name: 'racing-category', params: { type: 'GREY_HOUND_RACING' } }"
                            @click="type = 'GREY_HOUND_RACING'" :class="{ 'active': type == 'GREY_HOUND_RACING' }" aria-label="Greyhound Racing">
                            <img loading="lazy" src="@/assets/images/greyhound.webp" alt="grey hound">
                            <span>Greyhound Racing</span>
                        </router-link>
                    </li>
                    <li class="top-game-bx">
                        <a href="Javascript:void(0);" @click="goToSportsbook()" aria-label="Sportbook">
                            <img src="@/assets/images/premium-notebook.cfec1a1.webp" alt="premium notebook">
                            <span>{{ translatedLangData('sportsbook','Sportbook')}}</span>
                        </a>
                    </li>
                    <li class="top-game-bx">
                        <router-link to="/live-casino" aria-label="Live Casino">
                            <img src="@/assets/images/live-casino.761f895.webp" alt="live casino">
                            <span> {{ translatedLangData('live-casino','Live Casino')}}</span>
                        </router-link>
                    </li>
                    <li class="top-game-bx">
                        <router-link to="/crash-games" aria-label="crash games">
                            <img src="@/assets/images/crash-img.webp" alt="crash games ">
                            <span>{{ translatedLangData('crash-games','crash games')}}</span>
                        </router-link>
                    </li>
                    <li class="top-game-bx">
                        <router-link to="/live-card" aria-label="Live Card">
                            <img src="@/assets/images/live-card.c981209.webp" alt="live card">
                            <span> {{ translatedLangData('live-card','Live Card')}}</span>
                        </router-link>
                    </li>
                    <li class="top-game-bx">
                        <router-link to="/slot-games" aria-label="slot games">
                            <img src="@/assets/images/slot-games.ccf3217.webp" alt="slot">
                            <span>{{ translatedLangData('slot-games','slot games')}}</span>
                        </router-link>
                    </li>
                    <li class="top-game-bx">
                        <router-link to="/matka" aria-label="Matka">
                            <img src="@/assets/images/gold-pot.webp" alt="gold pot">
                            <span>{{ translatedLangData('matka','Matka')}}</span>
                        </router-link>
                    </li>
                </ul>
            </div>

        </div>
    </section>

    <section class="nav-in-mobile-view pb-2">
        <div class="sports-nav-details">
            <ul class="nav">
                <li class="v-mobile-tab nav-item">
                    <router-link to="/" class="nav-link" aria-label="Inplay">
                        <img src="@/assets/images/inplay.a7c4dae.webp" alt="inplay.a7c4dae">
                        <span>{{ translatedLangData('inplay','Inplay')}}</span>
                    </router-link>
                </li>
                <li class="v-mobile-tab nav-item">
                    <router-link to="/sports/cricket/4" class="nav-link" aria-label="Sports Exch">
                        <img src="@/assets/images/sportsbook.5e7a4f5.webp" alt="sports book">
                        <span>{{ translatedLangData('sports-exch','Sports Exch')}}</span>
                    </router-link>
                </li>
                <li class="v-mobile-tab nav-item">
                    <a href="Javascript:void(0);" @click="goToSportsbook()" class="nav-link" aria-label="Sportsbook">
                        <img src="@/assets/images/premium-notebook.cfec1a1.webp" alt="premium notebook">
                        <span>{{ translatedLangData('sportsbook','Sportsbook')}}</span>
                    </a>
                </li>
                <li class="v-mobile-tab nav-item">
                     <router-link to="/cricket-fight" class="nav-link" aria-label="Cricket Fight">
                        <img src="@/assets/images/cricket-bat.svg" alt="cricket bat">
                        <span>{{ translatedLangData('cricket-fight','Cricket Fight')}}</span>
                    </router-link>
                </li>
                <li>
                    <a href="#more-item-tabs" data-bs-toggle="modal" aria-label="More">
                        <img src="@/assets/images/app.webp" alt="app ">
                        <span class="text-white show-more-item-h">{{ translatedLangData('more','MORE')}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </section>

    <ShowMoreSports :non_custom_sports="non_custom_sports" :custom_sports="custom_sports"/>
</template>

<script>
import ShowMoreSports from '../modal/MobileSportsSelection.vue';
import clickApi from '../../services/click-api';
import * as apiName from '../../services/urls';

export default {
    name: "SportSelection",
    inject:['translatedLangData'],
    components:{
        ShowMoreSports
    },
    data: () => ({
        non_custom_sports: [],
        custom_sports: [],
        clickApiUrl: process.env.VUE_APP_CLICK_API + 'v1/aws-s3/download/',
        type: 'INPLAY'
    }),
    computed:{
        sortedNonCustomSports() {
            const order = ['cricket', 'football', 'tennis'];
            return this.non_custom_sports.sort((a, b) => {
                const aIndex = order.indexOf(a.name.toLowerCase());
                const bIndex = order.indexOf(b.name.toLowerCase());

                if (aIndex !== -1 || bIndex !== -1) {
                return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
                }
                return a.rank - b.rank;
            });
        }
    },
    created() {
        this.get_sportsList()
        // if(localStorage.getItem('sportId')){
        //     localStorage.removeItem('sportId')
        // }
    },
    methods: {
        checkInEventMange(type) {
            return this.$store.getters.eventManage ? this.$store.getters.eventManage?.filter(s => s.type == type).length != 0 ? false : true : true;
        },
        get_sportsList() {
            this.loading = true;
            clickApi.get(apiName.GET_SPORTS).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports = []
                        for (var i = 0; i < all_sports.length; i++) {
                            if (all_sports[i].is_custom == 0) {
                                this.non_custom_sports.push(all_sports[i])
                            } else {
                                this.custom_sports.push(all_sports[i])
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list = {}
                        sport_list['custom'] = this.custom_sports
                        sport_list['non_custom'] = this.sortedNonCustomSports;
                        this.$store.dispatch('setSportsList',sport_list);
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error);
                }
            });
        },
        goToSportsbook() {
            let isLogin = this.$store.getters.isAuthenticated;
            if(isLogin) {
                this.$router.push({ name: 'sports-book' })
            } else {
                this.showErrorModalFunc('Please Login to access Sportsbook !!');
            } 
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        }
    }
}
</script>