<template>
    <footer class="exchange-and-online-betting-sec" loading="lazy">
        <div class="remove-padding">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                    <div>
                        <div class="exchange-fair-ply-logo">
                             <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="logo">
                           <!-- <img src="@/assets/images/website-super-winlogo.webp" alt="logo" class="web-logo" /> -->
                        </div>
                        <div class="trusted-betting-text">
                            <p>© Established in 2019, {{domainName}} is the most trusted betting exchange and leading online
                                casino.</p>
                        </div>
                        <div class="horizontal-line-full">
                            <hr>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-sm-3 col-md-3 col-lg-2">
                    <div class="name-of-game">
                        <h3>{{ translatedLangData('games','Game')}}</h3>
                        <ul>
                            <!-- <li><a href="/live-card"> Live Card </a></li>
                            <li><a href="/online-cricket-betting"> Cricket Betting </a></li>
                            <li><a href="/ipl-betting"> IPL Betting </a></li>
                            <li><a href="/soccer-betting"> Football Betting </a></li>
                            <li><a href="/tennis-betting"> Tennis Betting </a></li>
                            <li><a href="/crash-games"> Live Casino Betting </a></li>
                            <li><a href="/MONGO-online-app"> {{ domainName }} Online Betting App </a></li> -->

                            <li>
                                <router-link to="/live-card"> {{ translatedLangData('live-card','Live Card')}} </router-link>
                            </li>
                            <li><router-link to="/online-cricket-betting"> {{ translatedLangData('cricket-betting','Cricket Betting')}} </router-link></li>
                            <li><router-link to="/ipl-betting"> {{ translatedLangData('ipl-betting','IPL Betting')}} </router-link></li>
                            <li><router-link to="/soccer-betting">{{ translatedLangData('football-betting','Football Betting')}} </router-link></li>
                            <li><router-link to="/tennis-betting"> {{ translatedLangData('tennis-betting','Tennis Betting')}} </router-link></li>
                            <li><router-link to="/crash-games"> {{ translatedLangData('live-casino-betting','Live Casino Betting')}} </router-link></li>
                            <li><router-link to="/MONGO-online-app"> {{ domainName }} {{ translatedLangData('online-betting-app','Online Betting App')}} </router-link></li>

                        </ul>
                    </div>
                </div>
                <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                    <div class="name-of-game">
                        <h3>{{ translatedLangData('company','Company')}}</h3>
                        <ul>
                            <li>
                                <router-link to="/static/ABOUT_US" > {{ translatedLangData('about-us','About Us')}} </router-link>
                                <!-- <a href="/static/ABOUT_US"> About Us </a> -->
                            </li>
                            <li>
                                <router-link to="/rules-regulations"> {{ translatedLangData('rule-regulations','Rules & Regulation')}} </router-link>
                            </li>

                            <li>

                                <router-link to="/static/PRIVACY_POLICY" > {{ translatedLangData('privacy-policy','Privacy Policy')}} </router-link>

                                <!-- <a href="/static/PRIVACY_POLICY">Privacy Policy</a> -->
                            </li>
                            <!-- <li><a href="#"> Sitemap </a></li> -->
                            <li>
                                <router-link to="responsible-gambling">{{ translatedLangData('responsible-gambling','Responsible Gambling')}}</router-link>
                            </li>
                            <li>
                                <router-link to="/static/TERM_CONDITIONS">{{ translatedLangData('terms-and-conditions','Terms And Conditions')}} </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-6 col-sm-4 col-md-2 col-lg-2">
                    <div class="name-of-game">
                        <h3>{{ translatedLangData('resources','RESOURCES')}}</h3>
                        <ul>
                            <!-- <li><a href="#"> Blogs </a></li> -->
                            <li>
                                <!-- <router-link to="/static/NEWS"> {{domainName}} News</router-link> -->
                                <a href="#"> {{domainName}} {{ translatedLangData('news','News')}}</a>
                            </li>
                            <li><router-link to="/sports-book" > {{ translatedLangData('sportsbook-betting','Sportsbook Betting')}} </router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6 col-sm-2 col-md-2 col-lg-1">
                    <div class="name-of-game">
                        <ul class="faq-sec">
                            <!-- <li><a href="#"> Affiliate </a></li> -->
                            <li>
                                <router-link to="/faqs">{{ translatedLangData('faq','FAQ')}}</router-link>
                            </li>
                            <li>
                                <!-- <router-link to="/static/TUTORIALS"> Tutorials</router-link> -->
                            </li>
                            <li>
                                <router-link  to="/markets" >{{ translatedLangData('market','Market')}} </router-link>
                            </li>
                            <li>
                                <router-link to="/static/LOYALTY"> {{ translatedLangData('loyalty','Loyalty')}} </router-link>
                                <!-- <a href="/static/LOYALTY">Loyalty</a> -->
                                </li>
                            <!-- <li>
                                <a href="/contact-us"> Contact Us </a>
                            </li> -->
                            <li>
                                <a :href="'//wa.me/'+siteSettings?.social_link?.whatsappno1" target="_blank" 
                                v-if="siteSettings?.social_link?.whatsappno1">  {{ translatedLangData('contact-us','Contact Us')}} </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                    <div class="socail-link" v-if="siteSettings?.social_link">
                        <ul>
                            <li v-if="siteSettings.social_link.facebook_link">
                                    <a :href="siteSettings.social_link.facebook_link" target="_blank" aria-label="Facebook">
                                        <img src="@/assets/images/facebook.webp" alt="facebook">
                                    </a>
                                </li>
                                <li v-if="siteSettings.social_link.telegram_link">
                                    <a :href="siteSettings.social_link.telegram_link" target="_blank" aria-label="Telegram">
                                        <img src="@/assets/images/telegram.webp" alt="telegram">
                                    </a>
                                </li>
                                <li v-if="siteSettings.social_link.instagram_link">
                                    <a :href="siteSettings.social_link.instagram_link" target="_blank" aria-label="Instagram">
                                        <img src="@/assets/images/instagram.webp" alt="instagram">
                                    </a>
                                </li>
                                <li v-if="siteSettings.social_link.youtube_link">
                                    <a :href="siteSettings.social_link.youtube_link" target="_blank" aria-label="YouTube">
                                        <img src="@/assets/images/youtube.webp" alt="youtube">
                                    </a>
                                </li>
                                <li v-if="siteSettings && siteSettings?.business_type == 2">
                                    <a :href="'//wa.me/'+siteSettings?.social_link?.whatsappno1" target="_blank" 
                                    v-if="siteSettings?.social_link?.whatsappno1" aria-label="WhatsApp">
                                        <img src="@/assets/images/whatsapp.webp" alt="whatsapp">
                                    </a>
                                </li>
                        </ul>
                    </div>
<!-- 
                    
                    <section class="social-icon-list">
                        <div class="socail-link">
                            <ul>
                                <li><a href="#"><img src="@/assets/images/facebook.svg" alt=""></a></li>
                                <li><a href="#"><img src="@/assets/images/twitter.svg" alt=""></a></li>
                                <li><a href="#"><img src="@/assets/images/telegram.svg" alt=""></a></li>
                                <li><a href="#"><img src="@/assets/images/instagram.svg" alt=""></a></li>
                                <li><a href="#"><img src="@/assets/images/youtube.svg" alt=""></a></li>
                                <li><a href="#"><img src="@/assets/images/whatsapp.svg" alt=""></a></li>
                            </ul>
                        </div>
                    </section> -->

                </div>
            </div>

            <div class="horizontal-line-full">
                <hr>
            </div>

            <div class="gambling-involved-sec">
                <div class="disclaimer-text disclaimer-text-in-mobile" id="disclaimer-details" v-if="showDisclimer">
                    <span>Disclaimer :</span>
                    <button @click="showDisclimer = false" class="close-desclaimer">x</button>
                    <p>Please note that Gambling involves a financial risk and could be addictive over time if not practised
                        within limits. Only 18+ people should use the services and should use it responsibly. Players should
                        be aware of any financial risk and govern themselves accordingly.</p>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-3 col-lg-2">
                        <div class="gaming-image">
                            <img src="@/assets/images/gc.webp" alt="gc">
                        </div>
                    </div>
                    <div class="col-6 col-sm-9 col-md-9 col-lg-10">
                        <div class="disclaimer-text" >
                            <span>Disclaimer :</span>
                            <p>Please note that Gambling involves a financial risk and could be addictive over time if not
                                practised within limits. Only 18+ people should use the services and should use it
                                responsibly. Players should be aware of any financial risk and govern themselves
                                accordingly.</p>
                        </div>
                        <div class="disclaimer-box">
                            <button type="button" id="btn-18" @click="showDisclimer = true">
                                <span>18+</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="horizontal-line-full last">
                <hr>
            </div>

            <div class="copy-right-sec">
                <p>© 2024 {{$store?.getters?.siteSettings?.domain_name}}. All Rights Reserved.</p>
            </div>
            
            <div class="horizontal-line-full last">
                <hr>
            </div>
           
        </div>
    </footer>
</template>
<script>
export default{
    name:'Footer',
    data(){
        return{
            showDisclimer: false
        }
    },
    inject:['translatedLangData'],
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        },

    },
}
</script>