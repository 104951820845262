const CricketFightModule  = () => import(/* webpackChunkName: "my-market-module" */ './views/module.vue');
const CricketFight  = () => import(/* webpackChunkName: "my-market-module" */ './views/CricketFight.vue');
const MatchDetails  = () => import(/* webpackChunkName: "my-market-module" */ './views/MatchDetails.vue');
const PlaceBet  = () => import(/* webpackChunkName: "my-market-module" */ './views/PlaceBet.vue');
const MyContests = () => import(/* webpackChunkName: "my-market" */ './views/MyContests.vue');
const MyContestBetDetails = () => import(/* webpackChunkName: "my-market" */ './views/MyContestBetDetails.vue');

const CricketFightRoutes = {
    path: '/cricket-fight',
    component: CricketFightModule ,
    children: [
        
        {
            path: '',
            name: 'CricketFight',
            component: CricketFight,
        },

        {
            path: ':id',
            name: 'MatchDetails',
            component: MatchDetails,
        },

        {
            path: ':id/place-bet',
            name: 'PlaceBet',
            component: PlaceBet
        },

        {
            path: 'my-contests',
            name: 'MyContests',
            component: MyContests
        },
        {
            path: 'my-contests/:id',
            name: 'MyContestBetDetails',
            component: MyContestBetDetails
        },        

    ],
}

export default CricketFightRoutes;