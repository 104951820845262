<template>
    <div class="modal fade feedbackModal" id="feedbackModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
     <div class="modal-dialog onload-modal  modal-dialog-centered">
         <div class="modal-content feedbackModal-wrapper">
            <div class="modal-header border-0">
                <h4 class="modal-title text-white">{{ translatedLangData('feedback','Feedback')}}</h4>
               <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
            </div>
            <div class="modal-body feedback-modal-body-wrapper">

                <div class="container">
                    <textarea v-model="feedbackData" @keyup="checkValidation()" class="form-control" :placeholder = "translatedLangData('Write-a-comment-or','Write a comment or describe a problem.')" id="floatingTextarea2" maxlength="250" />
                    <div v-if="showValidationError" class="mt-2">
                        <p class="wrong-message">{{ translatedLangData('feedback-should-not-be-empty','Please Enter Your Feedback')}}.</p>
                    </div>
                </div>
                
            </div>

            <div class="modal-footer border-0 feedback-footer">
                <button class="btn thm-but thm-bor-but feedback-sub-btn" @click="feedbackMethodCall()" ref="close_btn">{{ translatedLangData('submit','Submit')}}</button>
                <button class="btn thm-but feedback-close-btn" ref="close_btn" data-bs-dismiss="modal">{{ translatedLangData('close','Close')}}</button>
            </div>
            
         </div>
      </div>
      </div>
</template>

<script>
import axios from 'axios';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import { mapGetters } from 'vuex'

export default {
    name: "FeedbackModal",
    inject:['translatedLangData'],
    data(){
        return{
            feedbackData:null,
            showValidationError: false,
        }
    },
    computed: {
        ...mapGetters([
            'siteSettings',
        ])
    },
    methods:{
        checkValidation(){
            if(this.feedbackData.length > 0){
                this.showValidationError= false
            }
        },
        feedbackMethodCall(){
            if (this.feedbackData == "" || this.feedbackData == null) {
                this.showValidationError = true;
            }
            else if(this.feedbackData.length < 6){
                this.$refs.close_btn.click();
                this.feedbackData=null
                this.$emit('close');
                this.$router.push('/')
                this.$emit('error-modal','Feedback Should Be Atleat 6 Characters')
            }
            else {
                this.feedbackServiceCall();
            }
        },
        feedbackServiceCall() {
            this.loading = true;
            let data = {
                "whitelabel_id": this.siteSettings?.white_label_id,
                "feedback": this.feedbackData,
            };
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.FEEDBACK_API, data, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal',response.data.debug[0])
                        }
                        else {
                            this.$emit('success-modal','Feedback Added Succesfully');
                            this.$refs.close_btn.click();
                            this.feedbackData=null
                            this.$emit('close');
                            this.$router.push('/')
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal',error[0]);
                }
            });
        },
    },
    emits: ['close', 'success-modal', 'error-modal'],
};
</script>

<style scoped>
textarea{
    height: 120px;
}
</style>